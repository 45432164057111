/* src/App.css */

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.App-header {
  position: relative;
  z-index: 1;
  padding: 100px 20px;
}

.App-header h1 {
  font-size: 4em; /* Increased title size */
  margin-bottom: 30px; /* Added more space below title */
}

.App-header p {
  font-size: 1.5em;
  max-width: 800px;
  margin: 0 auto 30px auto; /* Added space below subtitle */
}

blockquote {
  font-size: 1.2em;
  font-style: italic;
  margin: 20px auto;
  max-width: 700px;
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-left: 5px solid #fff;
  border-radius: 5px;
}

blockquote span {
  display: block;
  margin-top: 10px;
  font-size: 1em;
  font-style: normal;
  text-align: right;
}

.waitlist-form {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.waitlist-form input {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  width: 250px;
  font-size: 1em;
}

.waitlist-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #6a5acd; /* SlateBlue */
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.waitlist-form button:hover {
  background-color: #836fff; /* Lighter SlateBlue */
}

.success-message {
  margin-top: 20px;
  color: #32cd32; /* LimeGreen */
  font-size: 1.2em;
}

/* Support Email Styles */
.support-email {
  margin-top: 50px; /* Adds space above the support email section */
}

.support-email blockquote {
  background: rgba(255, 255, 255, 0.2); /* Different background color */
  border-left: 5px solid #ffa500; /* Orange border */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #6a5acd, #836fff, #8a2be2, #4b0082);
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite; /* Slower animation */
  z-index: 0;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

footer {
  position: absolute;
  bottom: 30px;
  width: 100%;
  z-index: 1;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.contact-info a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-info svg {
  margin-right: 10px;
  vertical-align: middle;
}

/* Privacy Policy Styles */

.privacy-policy {
  text-align: left;
  font-size: 0.9em; /* Smaller font */
  color: white;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.privacy-policy h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.privacy-policy h3 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy h4 {
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 5px;
}

.privacy-policy p {
  line-height: 1.5;
  margin-bottom: 10px;
}

.privacy-policy ul {
  margin-left: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.privacy-policy li {
  margin-bottom: 5px;
}

.privacy-policy a {
  color: white;
  text-decoration: underline;
}

/* Terms of Service Styles */

.terms-of-service {
  text-align: left;
  font-size: 0.9em; /* Smaller font */
  color: white;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.terms-of-service h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.terms-of-service h3 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-of-service h4 {
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 5px;
}

.terms-of-service p {
  line-height: 1.5;
  margin-bottom: 10px;
}

.terms-of-service ul {
  margin-left: 20px;
  margin-bottom: 10px;
  text-align: left;
}

.terms-of-service li {
  margin-bottom: 5px;
}

.terms-of-service a {
  color: white;
  text-decoration: underline;
}

/* Responsive Design */

@media (max-width: 1024px) {
  .App-header {
    padding: 80px 20px;
  }

  .App-header h1 {
    font-size: 3.5em;
  }

  .App-header p {
    font-size: 1.3em;
    max-width: 700px;
  }

  blockquote {
    font-size: 1.1em;
    max-width: 600px;
    padding: 15px;
  }

  .waitlist-form {
    flex-direction: column;
    align-items: center;
  }

  .waitlist-form input {
    width: 80%;
    max-width: 300px;
  }

  .waitlist-form button {
    width: 50%;
    max-width: 200px;
  }

  .contact-info {
    gap: 30px;
  }

  .contact-info a {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .App-header {
    padding: 60px 20px;
  }

  .App-header h1 {
    font-size: 3em;
  }

  .App-header p {
    font-size: 1.2em;
    max-width: 600px;
  }

  blockquote {
    font-size: 1em;
    max-width: 500px;
    padding: 10px;
  }

  .waitlist-form {
    gap: 8px;
  }

  .waitlist-form input {
    width: 90%;
    max-width: 250px;
  }

  .waitlist-form button {
    width: 70%;
    max-width: 180px;
  }

  .contact-info {
    gap: 20px;
  }

  .contact-info a {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .App-header {
    padding: 40px 20px;
  }

  .App-header h1 {
    font-size: 2.5em;
  }

  .App-header p {
    font-size: 1.1em;
    max-width: 100%;
  }

  blockquote {
    font-size: 0.95em;
    max-width: 100%;
    padding: 8px;
  }

  .waitlist-form {
    gap: 6px;
  }

  .waitlist-form input {
    width: 100%;
    max-width: 220px;
  }

  .waitlist-form button {
    width: 100%;
    max-width: 200px;
  }

  .contact-info {
    flex-direction: column;
    gap: 15px;
  }

  .contact-info a {
    font-size: 1em;
  }
}
